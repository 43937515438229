<template>

</template>

<script type="text/babel">
    const LOGIN_URL = 'login';

    export default {

        data() {
            return {
            }
        },
        async beforeCreate(){
            await this.$store.dispatch('authenticationStore/logout');
            this.$router.replace(LOGIN_URL);
        },
        methods:{
        }
    }
</script>