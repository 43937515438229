<template>
  <v-card>
  </v-card>
</template>

<script>
  export default {
    name: 'AuthenticateRedirect',

    data: () => ({
      config: {},
      info: {}
    }),
    async created(){ 
        
        if(!this.$route.query.code) return;
        let r  = await this.api.get(`/authenticate?code=${this.$route.query.code}`);
  
        this.$store.commit('authenticationStore/set_token_expiry',  r);
        this.$router.push('/'); 
    }
  }
</script>
