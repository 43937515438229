<template>
  <v-card elevation="5" class="pa-10 mt-10" d-flex align-center justify-center  style=" margin-left: auto; margin-right: auto;"  min-height="250" max-width="500">
    <p class="text-subtitle-2">Please enter your details</p>
    <form @submit.prevent="submit">
      <v-text-field
          v-model="form.email_address"
          label="Email Address"
          required
          email
          outlined
          
        ></v-text-field>
        <v-text-field
          v-model="form.password"
          type="password"
          label="Password"
          required
          outlined
          
        ></v-text-field>
        <v-btn
          color="primary"
          class="mr-4"
          type="submit"
        > submit</v-btn>
    </form>
  </v-card>
</template>

<script>
import { EventBus } from '../../modules/EventBus.js';
import { mapGetters, mapMutations } from 'vuex';
  export default {
    name: 'Login',


    data: () => ({
      form: {
        email_address: null,
        password: null
      }
    }),
    async created(){ 
     
      if(this.isLoggedIn){
        this.$router.push('/');
      }
    }, 
    computed:{
       ...mapGetters({
          isLoggedIn: 'authenticationStore/isLoggedIn'
      }),
    }, 
    methods: {
      async submit(){
        try {
          let response = await this.api.post(`/login`, this.form); 
          
          this.$store.dispatch('authenticationStore/authenticate',  response);
          EventBus.$emit('bootstrap'); 
          this.$router.push('/');
        } catch(err) {
          console.log("Err", err)
        }
      }
    }
  }
</script>
